import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Christmas carols are a time honored tradition. Draw a heatmap of their most
popular words.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactviz.holiday/datasets/christmas_carols_data.csv"
      }}>{`Dataset`}</a></p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/x141XrIuP50" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/2nv1j4mwr?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`Building these word clouds kicked my ass. Even had to ask the three wise men
for help.`}</p>
    <blockquote className="twitter-tweet" data-conversation="none" data-lang="en"><p lang="en" dir="ltr">So apparently combining useState and useMemo and promises makes your JavaScript crash hard.<br /><br />What am I doing wrong? <a href="https://twitter.com/ryanflorence?ref_src=twsrc%5Etfw">@ryanflorence</a> <a href="https://twitter.com/kentcdodds?ref_src=twsrc%5Etfw">@kentcdodds</a> <a href="https://twitter.com/dan_abramov?ref_src=twsrc%5Etfw">@dan_abramov</a> ? I thought useMemo was supposed to only run once and not infinite loop on me <a href="https://t.co/29OVRXxhuz">pic.twitter.com/29OVRXxhuz</a></p>&mdash; Swizec Teller (@Swizec) <a href="https://twitter.com/Swizec/status/1071456819107622913?ref_src=twsrc%5Etfw">December 8, 2018</a></blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`Turns out that even though `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` is for memoizing heavy computation, this
does not apply when said computation is asynchronous. You have to use
`}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`.`}</p>
    <p>{`At least until suspense and async comes in early 2019.`}</p>
    <p>{`Something about always returning the same Promise, which confuses `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` and
causes an infinite loop when it calls `}<inlineCode parentName="p">{`setState`}</inlineCode>{` on every render. That was fun.`}</p>
    <p>{`There's some computation that goes into this one to prepare the dataset. Let's
start with that.`}</p>
    <h2 {...{
      "id": "preparing-word-cloud-data"
    }}>{`Preparing word cloud data`}</h2>
    <p>{`Our data begins life as a flat text file.`}</p>
    <pre><code parentName="pre" {...{}}>{`Angels From The Realm Of Glory

Angels from the realms of glory
Wing your flight over all the earth
Ye, who sang creations story
Now proclaim Messiah's birth
Come and worship, come and worship
Worship Christ the newborn King
Shepherds in the fields abiding
Watching over your flocks by night
God with man is now residing
`}</code></pre>
    <p>{`And so on. Each carol begins with a title and an empty line. Then there's a
bunch of lines followed by an empty line.`}</p>
    <p>{`We load this file with `}<inlineCode parentName="p">{`d3.text`}</inlineCode>{`, pass it into `}<inlineCode parentName="p">{`parseText`}</inlineCode>{`, and save it to a
`}<inlineCode parentName="p">{`carols`}</inlineCode>{` variable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [carols, setCarols] = useState(null);

useEffect(() => {
  d3.text('/carols.txt')
    .then(parseText)
    .then(setCarols);
}, [!carols]);
`}</code></pre>
    <p>{`Typical `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`/`}<inlineCode parentName="p">{`useState`}</inlineCode>{` dance. We run the effect if state isn't set, the
effect fetches some data, sets the state.`}</p>
    <p>{`Parsing that text into individual carols looks like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function takeUntilEmptyLine(text) {
  let result = [];

  for (
    let row = text.shift();
    row && row.trim().length > 0;
    row = text.shift()
  ) {
    result.push(row.trim());
  }

  return result;
}

export default function parseText(text) {
  text = text.split('\\n');

  let carols = { 'All carols': [] };

  while (text.length > 0) {
    const title = takeUntilEmptyLine(text)[0];
    const carol = takeUntilEmptyLine(text);

    carols[title] = carol;
    carols['All carols'] = [...carols['All carols'], ...carol];
  }

  return carols;
}
`}</code></pre>
    <p>{`Our algorithm is based on a `}<inlineCode parentName="p">{`takeUntil`}</inlineCode>{` function. It takes lines from our text
until some condition is met.`}</p>
    <p>{`Basically:`}</p>
    <ol>
      <li parentName="ol">{`Split text into lines`}</li>
      <li parentName="ol">{`Run algorithm until you run out of lines`}</li>
      <li parentName="ol">{`Take lines until you encounter an empty line`}</li>
      <li parentName="ol">{`Assume the first line is a title`}</li>
      <li parentName="ol">{`Take lines until you encounter an empty line`}</li>
      <li parentName="ol">{`This is your carol`}</li>
      <li parentName="ol">{`Save title and carol in a dictionary`}</li>
      <li parentName="ol">{`Splat carrol into the `}<inlineCode parentName="li">{`All carols`}</inlineCode>{` blob as well`}</li>
    </ol>
    <p>{`We'll use that last one for a joint word cloud of all Christmas carols.`}</p>
    <h2 {...{
      "id": "calculating-word-clouds-with-d3-cloud"
    }}>{`Calculating word clouds with d3-cloud`}</h2>
    <p>{`With our carols in hand, we can build a word cloud. We'll use the wonderful
`}<a parentName="p" {...{
        "href": "https://github.com/jasondavies/d3-cloud"
      }}>{`d3-cloud`}</a>{` library to handle layouting
for us. Our job is to feed it data with counted word frequencies.`}</p>
    <p>{`Easiest way to count words is with a loop`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function count(words) {
  let counts = {};

  for (let w in words) {
    counts[words[w]] = (counts[words[w]] || 0) + 1;
  }

  return counts;
}
`}</code></pre>
    <p>{`Goes over a list of words, collects them in a dictionary, and does `}<inlineCode parentName="p">{`+1`}</inlineCode>{` every
time.`}</p>
    <p>{`We use that to feed data into `}<inlineCode parentName="p">{`d3-cloud`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function createCloud({ words, width, height }) {
  return new Promise(resolve => {
    const counts = count(words);

    const fontSize = d3
      .scaleLog()
      .domain(d3.extent(Object.values(counts)))
      .range([5, 75]);

    const layout = d3Cloud()
      .size([width, height])
      .words(
        Object.keys(counts)
          .filter(w => counts[w] > 1)
          .map(word => ({ word }))
      )
      .padding(5)
      .font('Impact')
      .fontSize(d => fontSize(counts[d.word]))
      .text(d => d.word)
      .on('end', resolve);

    layout.start();
  });
}
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`createCloud`}</inlineCode>{` function gets a list of words, a width, and a height. Returns
a promise because d3-cloud is asynchronous. Something about how long it might
take to iteratively come up with a good layout for all those words. It's a hard
problem. 🤯`}</p>
    <p>{`(that's why we're not solving it ourselves)`}</p>
    <p>{`We get the counts, create a `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` logarithmic scale for sicing, and invoke
the D3 cloud.`}</p>
    <p>{`That takes a `}<inlineCode parentName="p">{`size`}</inlineCode>{`, a list of words without single occurrences turned into
`}<inlineCode parentName="p">{`{ word: 'bla' }`}</inlineCode>{` objects, some padding, a font size method using our
`}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` scale, a helper to get the word and when it's all done the `}<inlineCode parentName="p">{`end`}</inlineCode>{`
event resolves our promise.`}</p>
    <p>{`When that's set up we start the layouting process with `}<inlineCode parentName="p">{`layout.start()`}</inlineCode></p>
    <h2 {...{
      "id": "animating-words"
    }}>{`Animating words`}</h2>
    <p>{`Great. We've done the hard computation, time to start rendering.`}</p>
    <p>{`We'll need a self-animating `}<inlineCode parentName="p">{`<Word>`}</inlineCode>{` componenent that transitions itself into a
new position and angle. CSS transitions can't do that for us, so we'll have to
use D3 transitions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class Word extends React.Component {
  ref = React.createRef();
  state = { transform: this.props.transform };

  componentDidUpdate() {
    const { transform } = this.props;
    d3.select(this.ref.current)
      .transition()
      .duration(500)
      .attr('transform', this.props.transform)
      .on('end', () => this.setState({ transform }));
  }

  render() {
    const { style, children } = this.props,
      { transform } = this.state;

    return (
      <text
        transform={transform}
        textAnchor="middle"
        style={style}
        ref={this.ref}
      >
        {children}
      </text>
    );
  }
}
`}</code></pre>
    <p>{`We're using my
`}<a parentName="p" {...{
        "href": "https://swizec.com/blog/declarative-d3-transitions-react/swizec/8323"
      }}>{`Declarative D3 transitions with React`}</a>{`
approach to make it work. You can read about it in detail on my main blog.`}</p>
    <p>{`In a nutshell:`}</p>
    <ol>
      <li parentName="ol">{`Store the transitioning property in state`}</li>
      <li parentName="ol">{`State becomes a sort of staging area`}</li>
      <li parentName="ol">{`Take control of rendering in `}<inlineCode parentName="li">{`componentDidUpdate`}</inlineCode>{` and run a transition`}</li>
      <li parentName="ol">{`Update state after transition extends`}</li>
      <li parentName="ol">{`Render `}<inlineCode parentName="li">{`text`}</inlineCode>{` from state`}</li>
    </ol>
    <p>{`The result are words that declaratively transition into their new positions.
Try it out.`}</p>
    <h2 {...{
      "id": "putting-it-all-together"
    }}>{`Putting it all together`}</h2>
    <p>{`Last step in the puzzle is that `}<inlineCode parentName="p">{`<WordCloud>`}</inlineCode>{` component that was giving me so
much trouble and kept hanging my browser. It looks like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`export default function WordCloud({ words, forCarol, width, height }) {
  const [cloud, setCloud] = useState(null);
  useEffect(() => {
    createCloud({ words, width, height }).then(setCloud);
  }, [forCarol, width, height]);

  const colors = chroma.brewer.dark2;

  return (
    cloud && (
      <g transform={\`translate(\${width / 2}, \${height / 2})\`}>
        {cloud.map((w, i) => (
          <Word
            transform={\`translate(\${w.x}, \${w.y}) rotate(\${w.rotate})\`}
            style={{
              fontSize: w.size,
              fontFamily: 'impact',
              fill: colors[i % colors.length],
            }}
            key={w.word}
          >
            {w.word}
          </Word>
        ))}
      </g>
    )
  );
}
`}</code></pre>
    <p>{`A combination of `}<inlineCode parentName="p">{`useState`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` makes sure we run the cloud
generating algorithm every time we pick a different carol to show, or change
the size of our word cloud. When the effect runs, it sets state in the `}<inlineCode parentName="p">{`cloud`}</inlineCode>{`
constant.`}</p>
    <p>{`This triggers a render and returns a grouping element with its center in the
center of the page. `}<inlineCode parentName="p">{`d3-cloud`}</inlineCode>{` creates coordinates spiraling around a center.`}</p>
    <p>{`Loop through the cloud data, render a `}<inlineCode parentName="p">{`<Word>`}</inlineCode>{` component for each word. Set a
transform, a bit of style, the word itself.`}</p>
    <p>{`And voila, a declaratively animated word cloud with React and D3 ✌️`}</p>
    <blockquote className="twitter-tweet" data-conversation="none" data-lang="en"><p lang="en" dir="ltr">With your powers combined I got it working! Thanks guys :) <a href="https://t.co/7qKr6joeRC">pic.twitter.com/7qKr6joeRC</a></p>&mdash; Swizec Teller (@Swizec) <a href="https://twitter.com/Swizec/status/1071468363740639232?ref_src=twsrc%5Etfw">December 8, 2018</a></blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`Original data from
`}<a parentName="p" {...{
        "href": "https://github.com/drewconway/ZIA/tree/master/R/Very%20Data%20Christmas"
      }}>{`Drew Conway`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      